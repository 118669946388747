<template>
   <div class="main-content dirr" :style="$vuetify.theme.dark ? '' : 'background-color: #f7f7f7;'">
    <v-row class="form-container container dirr" >
      <v-col cols="12" md="4" class="info-section">
        <div class="header text-center">
          <img src="assets/img/quran_competition/q-main-logo.svg" alt="شعار" class="q-logo" />
            <hr class="my-3 opacity-5">
          <p class="my-2">{{$t('deans_title')}}</p>
          <div class="d-flex py-2 flex-center">
            <img src="assets/img/quran_competition/calendar.svg" height="20px"/>
            <p class="mx-2 cu-q-font"> {{ $t('deans_end') }} : 05-01-2025</p>
          </div>
          <div class="d-flex flex-center">
            <img src="assets/img/quran_competition/map-pin.svg" height="20px"/>
            <p class="mx-2 cu-q-font">{{ $t('deans_location') }}</p>
          </div>
        </div>
      </v-col>
  
      <v-col cols="12" md="8" class="form-section" :style="$vuetify.theme.dark ? 'background-color: rgb(41, 44, 44)' : 'background-color: #fff;'">
        <div class="d-flex my-3">
            <img src="assets/img/quran_competition/small-logo.svg" alt="شعار" class="mx-2" height="24px"/>
            <h3>{{ $t('deans_form') }}</h3>
        </div>
        <v-form ref="form" v-model="valid">
          <v-text-field class="mb-4"
            v-model="name"
            :label="$t('account_name')"
            :rules="[rules.required]"
            outlined
            required
    
          />
  
          <v-row>
            <v-col class="col-12 col-sm-6">
              <v-text-field class="mb-4"
                v-model="age"
                :label="$t('deans_age')"
                :rules="[rules.required]"
                type="number"
                outlined
                required
                hide-details
              />
            </v-col>
  
            <v-col class="col-12 col-sm-6">
              <v-autocomplete class="mb-4"
                v-model="country"
                :items="countries"
                :label="$t('country')"
                :rules="[rules.required]"
                item-text="name"
                item-value="name"
                 no-data-text="No countries found"
                outlined
                required
                hide-details
              />
            </v-col>
          </v-row>
  
          <v-row>
            <v-col class="col-12 col-sm-6">
             
              <VuePhoneNumberInput
              style="direction: ltr!important"
              :dark="$vuetify.theme.dark"
              dark-color="transparent"
      v-model="yourValue"
       @update="handleUpdate"
      :hint="$t('deans_phnoe_hint')"
      :default-country-code="'IQ'"
      :size="'lg'"
      :ignored-countries="['IL']"
      :translations="translations"
    /> 
              <!-- <v-text-field class="mb-1" 
                v-model="phone"
                :label="$t('Phone_Num')"
                :rules="[rules.required]"
                outlined
                required
                hide-details
                prepend-inner-icon="mdi-phone"
              >
                <template v-slot:append-outer>
                  <v-select 
                    :menu-props="{closeOnClick: true,closeOnContentClick: true,}"
                    v-model="selectedPhoneCode"
                    :items="countries"
                    item-text="country_code"
                    item-value="country_code"
                    hide-details
                    dense
                    style="min-width: 60px; max-width: 90px; direction: ltr;">
>
                    <template v-slot:item="{ item }">
                      <v-list-item @click="selectedPhoneCode = item.country_code">
                        <v-list-item-avatar>
                          <img :src="'https://alkafeel.net/alkafeel_back_test/img/flags/'+item.sym.toLowerCase()+'.png'" alt="Flag" style="width: 20px; height: 15px;" />
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title style="direction: ltr;">{{ item.country_code }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-select>
                </template>
              </v-text-field> -->
              <div class="hint">{{ $t('deans_phnoe_hint') }}</div>
            </v-col>
            
            <v-col class="col-12 col-sm-6">
              <v-file-input class="mb-1"
                v-model="video"
                :label="$t('deans_video')"
                :rules="[rules.required]"
                accept="video/mp4"
                outlined
                required
                :hint="$t('deans_video_hint')"
                persistent-hint
                hide-details="auto"

              />
            
            </v-col>
  
            
          </v-row>
  
          <v-row>
            <v-col class="col-12 col-sm-6">
              <v-file-input class="mb-1"
                v-model="passport"
                :label="$t('host_pass')"
                :rules="[rules.required]"
                accept=".jpg,.jpeg,.png"
                outlined
                required
                :hint="$t('deans_passport_hint')"
                persistent-hint
                hide-details="auto"
              />
             
            </v-col>
  
            <v-col xs="12" sm="6">
              <v-file-input class="mb-1"
                v-model="personalId"
                :label="$t('deans_pic')"
                :rules="[rules.required]"
                accept=".jpg,.jpeg,.png"
                outlined
                required
                :hint="$t('deans_passport_hint')"
                persistent-hint
                hide-details="auto"
              />
            
              
            </v-col>
          </v-row>
  
          <v-btn @click="submit" :disabled="!valid" style="color:#fff;font-weight:bold" :loading="load" color="#183E38" class="btn-custom px-6">
            {{ $t('zyara_reg') }}
          </v-btn>
        </v-form>
      </v-col>
    </v-row>
   </div>
  </template>
  
  <script>
  import Swal from "sweetalert2";
  import VuePhoneNumberInput from 'vue-phone-number-input';
  import 'vue-phone-number-input/dist/vue-phone-number-input.css';


  export default {
    components: {
      VuePhoneNumberInput,
 
      
        },

    
   
    data() {
      return {
        valid: false,
        name: '',
        age: null,
        country: null,
        video: null,
        phone: null,
        passport: null,
        personalId: null,

        translations:{countrySelectorLabel: 'ar',
  countrySelectorError: 'Choisir un pays',
  phoneNumberLabel:this.$t('Phone_Num'),
  example: 'Exemple :'},
     

        countries: [],
        yourValue:"",
        responseMessage: '',
        load: false,
        selectedPhoneCode: '+964',
        rules: {
          required: value => !!value || this.$t('required_field'),
        },
      };
    },
    created() {
      this.get_country()
    },
    methods: {
      handleUpdate(updatedValue) {
        this.phone=updatedValue.formattedNumber;
    },
      get_country () {
          this.$axios.get("deans/getCountries", {})
          .then(res => {
              this.countries = res.data;
          })
      },
      copyToClipboard(text) {
        navigator.clipboard.writeText(text).then(() => {
          Swal.fire({
            icon: 'success',
            title: 'Copied!',
            text: 'Text has been copied to clipboard.',
            showConfirmButton: false,
            timer: 1500
          });
        }, (err) => {
          console.error('Could not copy text: ', err);
        });
      },
      swal (icon, text, timer, uid) {
        if (icon === 'success') {
          Swal.fire({
            title: this.responseMessage,
            // html: `${text} : ${uid} <i class="fas fa-copy" style="cursor: pointer;" @click="this.copyToClipboard('${uid}')"></i>`,
            html: text +  '  &nbsp <h2>' + uid +'</h2>',
            icon: icon,
            timer: timer,
            sconfirmButtonColor: '#B1BD52', 
            confirmButtonText: this.$t('msg_copy_code'),
            preConfirm: () => {
              const el = document.createElement('textarea');
              el.value = uid;
              document.body.appendChild(el);
              el.select();
              document.execCommand('copy');
              document.body.removeChild(el);
            }
          }).then(function() {
            return false
            // window.location = "/"; return false
          });
        } else {
          Swal.fire({
            title: this.responseMessage,
            text: text,
            icon: icon,
            timer: timer,
            showConfirmButton: false,
          }).then(function() {
            return false
            // window.location = "/"; return false
          });
        }
        
      },
      async submit() {
        if (this.$refs.form.validate()) {
          this.load = true;
          const formData = new FormData();
          formData.append('name', this.name);
          formData.append('age', this.age);
          formData.append('country', this.country);
          formData.append('phone', this.phone);
          // formData.append('phone_code', this.selectedPhoneCode);
          
          if (this.video) {
            formData.append('video', this.video);
          }
          if (this.passport) {
            formData.append('passport', this.passport);
          }
          if (this.personalId) {
            formData.append('personalId', this.personalId);
          }

          try {
            
            const response = await this.$axios.post('deans', formData, {
              headers: {
                'Content-Type': 'multipart/form-data' 
              }
            });
            this.load = false;
            this.responseMessage = response.data.msg;
            if (response.status == 200) {
              this.swal('success', this.$t('deans_code'), null, response.data.uid);


              this.valid=true;
              this.name= '';
              this.age= null;
              this.country= null;
              this.yourValue='';
              this.video= null;
              this.phone= null;
              this.passport= null;
              this.personalId= null;


              
            } else {
              this.swal('error', null, 3000, null);
            }
          } catch (error) {
            this.load = false;
            let errorMessage = this.$t('alert_try_again');
            if (error.response && error.response.data) {
              if (error.response.data.errors) {
                errorMessage = Object.values(error.response.data.errors).flat().join(', ');
              } else {
                errorMessage = error.response.data.msg || errorMessage;
              }
            }
            this.responseMessage = errorMessage;
            this.swal('error', null, 3000, null);
          }
        }
      },
    },
  };
  </script>
  
<style scoped>
  .main-content {
    display: flex;
    justify-content: center;
}
  .form-container {
    display: flex;
    align-items: stretch;
    max-width: 1250px;
    margin:12px 0px;

  }
  
  .form-section {
    padding: 30px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.055);
  }
  
  .info-section {
    padding: 30px;
    background-image: url('/assets/img/quran_competition/q-bg.png');
    color: white;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    background-size: cover;
  }
  
  .logo {
    width: 100px;
  }
  
  .btn-custom {
    background-color: #183E38;
    color: white;
  }
  
  .note {
    font-size: 0.8rem;
    color: gray;
    display: block;
    text-align: center;
    margin-bottom: 15px;
  }
  
  @media (max-width: 768px) {
    .main-content {
      flex-direction: column; 
    }
  }

  .q-logo {
    width: 150px;
  }

  .opacity-5 {
    opacity: .5;
  }

  .flex-center {
    justify-content: center;
  }

  .cu-q-font {
    color: #c3c3c3;
    font-size: 14px;
    margin: 4px;
  }

  .hint {
  font-size: 0.775rem; /* Adjust hint size */
  color: #6c757d; /* Adjust color, typically muted */
  margin-top: 5px; /* Add spacing between the input and hint */
}

  </style>
  